exports.components = {
  "component---src-pages-404-en-js": () => import("./../../../src/pages/404.en.js" /* webpackChunkName: "component---src-pages-404-en-js" */),
  "component---src-pages-404-it-js": () => import("./../../../src/pages/404.it.js" /* webpackChunkName: "component---src-pages-404-it-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-it-js": () => import("./../../../src/pages/index.it.js" /* webpackChunkName: "component---src-pages-index-it-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-en-js": () => import("./../../../src/pages/privacy.en.js" /* webpackChunkName: "component---src-pages-privacy-en-js" */)
}

